// @flow
import md5 from 'md5';
import {providerUrl} from './config';
import {VideoMeta} from './videoMeta';
import {Buffer} from 'buffer';
import {Parser} from 'xml2js';
import {Logger} from './logger';
import {Http} from './http';
import _defaultJSON from './defaultJSON';

class JSONProvider {
  isLive: boolean = false;
  isVod: boolean = false;
  embed: string;
  id: string;
  user: String;

  providerUrl: string;
  logger: Logger;
  config: any;
  defaultJSON: any;

  constructor(config: any) {
    this.config = config;
    this.logger = new Logger('JSONProvider');
    this.providerUrl = this.config.providerUrl !== undefined && this.config.providerUrl !== null ? this.config.providerUrl : providerUrl;
    this.embed = this.config.embed;
    if (this.embed.length == 8) {
      this.isLive = true;
      this.isVod = false;
    }
    if (this.embed.length == 10) {
      this.isLive = false;
      this.isVod = true;
      this.id = this.config.id;
    }
  }

  async init() {
    try {
      if (this.isVod) return await this.decodeVOD();
      if (this.isLive) return await this.decodeLIVE();
      throw new Error('404 not found');
    } catch (e) {
      return e;
    }
  }

  async decodeLIVE() {
    try {
      let result: VideoMeta = {};
      result['customization'] = {checkAdBlock: true};
      result['branding'] = {src: '', url: ''};
      result['basics'] = {
        title: null,
        description: null,
        tags: null
      };
      result.isLive = true;
      result.showInfoBtn = true;
      result = await this.decodeLIVEPLayer(result);
      return result;
    } catch (e) {
      // do nothing
    }
  }

  async decodeLIVEPLayer(result: VideoMeta) {
    try {
      const playerEnc = md5('definition-' + this.embed);
      let playerData = await Http.execute(`${this.providerUrl}/definition/players/${playerEnc}.json`, null, 'GET');
      result.streamNameLive = null;
      result.basics = {
        title: playerData.title,
        description: null,
        tags: null
      };
      try {
        result.playerSS = parseInt(playerData.ss);
      } catch (e) {
        //do nothing
      }
      result.analytics = {google: {key: playerData.keyGoogleCode}, realtime: {disabled: false}, ivc: {disabled: false}};
      result.user = playerData.user;
      this.user = result.user;

      try {
        const regStream = /live\/smil:?(.*)\.smil\/playlist|live\/(.*)\.stream\/playlist|live\/(.*)\/playlist/gm;
        let stream = regStream.exec(playerData.srcMobile)[1];
        result.streamNameLive = stream;
      } catch (e) {
        //do nothing
      }

      this.defaultJSON =
        this.config.defaultJSON !== undefined && this.config.defaultJSON
          ? this.config.defaultJSON
          : await _defaultJSON(this.user, this.config, this.providerUrl, result.streamNameLive);
      /**
       * Funcionalidad que permite poner en el json del javascript al instanciar el player rutas de cdn por usuario
       */
      if (this.defaultJSON[this.user] !== undefined) {
        try {
          this.defaultJSON.urls = this.defaultJSON[this.user].urls;
        } catch (e) {
          console.log(e);
          //do nothing
        }
        console.log(this.defaultJSON);
      }
      result.chatEnabled = false;
      result.playerId = this.embed;
      try {
        if (playerData.ss !== undefined && parseInt(playerData.ss) > 0) result.chatEnabled = true;
      } catch (e) {
        // do nothing
      }

      const xmlData = await this._decodeXMLPlayer(playerData.data);
      try {
        result.showInfoBtn = xmlData.menu === 'false' ? false : true;
      } catch (e) {
        // do nothing
      }
      result.geoBlocking = xmlData.geoBlocking;
      result.allowedDomains = xmlData.allowedDomains.filter(a => a.length);

      result.autoPlay = xmlData.autoplay == 'true' ? true : false;
      result.watermark = {
        pos: xmlData.logo_pos,
        src: xmlData.logo_src,
        target: xmlData.logo_url,
        isVisible: xmlData.logo_visible == 'true' ? true : false
      };

      result.branding = xmlData.branding;

      Object.assign(result.analytics, xmlData.analytics);
      result['ads'] = {ivc: xmlData.ivc_ads, vast: xmlData.vast};
      result.share = xmlData.share;
      result.images = {
        principal: playerData.prevPic,
        domainBlocked: xmlData.controls_bkd == 'false' ? false : xmlData.controls_bkd,
        geoBlocked: xmlData.controls_bkg == 'false' ? false : xmlData.controls_bkg
      };

      const regex = /^(https?|http):\/\/[.a-z]+[.a-z0-9-]+/gm;

      result.urls = {
        hls: playerData.srcMobile.replace('.f4m', '.m3u8').replace(regex, this.defaultJSON.urls[0]).replace('http:', 'https:')
      };

      // Si isDvr esta en el xml del player controlamos segun ese parametro, sino se controla segun el stream name (clasico)
      result.isDvr = false;
      if (xmlData.isDvr !== undefined) {
        if (xmlData.isDvr === true || xmlData.isDvr == 'true') {
          result.isDvr = true;
          result.urls.hls = result.urls.hls + '?DVR';
        } else {
          result.isDvr = false;
        }
      } else {
        result.isDvr = false;
        if (playerData.src.indexOf('dvr') > -1) {
          result.isDvr = true;
          result.urls.hls = result.urls.hls + '?DVR';
        }
      }

      result.customization = Object.assign(result.customization, {color: {value: xmlData.controls_color, alpha: parseFloat(xmlData.controls_alpha)}});
      result.customization['showTitle'] = xmlData.showTitle;
      result.customization['showCCBtn'] = xmlData.showCCBtn || false;
      result.isActive = true;
      result.isAudio = xmlData.onlyaudio ? true : false;
      return result;
    } catch (e) {
      return e;
    }
  }

  async _liveConfig() {
    const videoEnc = md5(`definition-config${this.embed}`);
    const data = await Http.execute(`${this.providerUrl}/definition/config/${videoEnc}.json`, null, 'GET');
    return data;
  }

  async decodeVOD() {
    let result: VideoMeta = {
      isLive: false,
      showInfoBtn: true
    };
    result['customization'] = {checkAdBlock: false};
    result['branding'] = {src: '', url: ''};
    result['basics'] = {
      title: null,
      description: null,
      tags: null
    };
    // result.showInfoBtn = true;
    result = await this.decodeVODPlayer(result);
    result = await this.videoData(result);
    result.captions = await this.vodCaptions();
    return result;
  }

  async decodeVODPlayer(result: VideoMeta) {
    const playerEnc = md5('definition-config-player-' + this.embed);
    const responsePlayer = await Http.execute(`${this.providerUrl}/definition/vod/players/${playerEnc}.json`, null, 'GET');
    let playerData = responsePlayer;
    result.playerId = responsePlayer.playerkey;
    result.geoBlocking = [];
    if (playerData.isGeoBLocked) {
      for (let item of Object.keys(playerData.geoBlocking)) {
        result.geoBlocking.push(item);
      }
    }
    result.analytics = {google: {key: playerData.keyGoogleCode}, realtime: {disabled: false}, ivc: {disabled: false}};
    result.allowedDomains = playerData.domainsBlocked.filter(a => a.length);
    result.user = playerData.user;
    this.user = result.user;

    this.defaultJSON =
      this.config.defaultJSON !== undefined && this.config.defaultJSON
        ? this.config.defaultJSON
        : await _defaultJSON(this.user, this.config, this.providerUrl);
    const xmlData = await this._decodeXMLPlayer(playerData.data);

    try {
      result.showInfoBtn = xmlData.menu === 'false' ? false : true;
    } catch (e) {
      // do nothing
    }

    result.autoPlay = xmlData.autoplay == 'true' ? true : false;
    result.watermark = {
      pos: xmlData.logo_pos,
      src: xmlData.logo_src,
      target: xmlData.logo_url,
      isVisible: xmlData.logo_visible == 'true' ? true : false
    };

    result.analytics = Object.assign(result.analytics, xmlData.analytics);
    result['ads'] = {ivc: xmlData.ivc_ads, vast: xmlData.vast};
    result.share = xmlData.share;
    result.images = {
      principal: null,
      domainBlocked: xmlData.controls_bkg,
      geoBlocked: xmlData.controls_bkg
    };

    result.branding = xmlData.branding;

    result.customization = Object.assign(result.customization, {color: {value: xmlData.controls_color, alpha: parseFloat(xmlData.controls_alpha)}});
    result.customization['showTitle'] = xmlData.showTitle;
    result.customization['showCCBtn'] = xmlData.showCCBtn || false;
    return result;
  }

  async _decodeXMLPlayer(data: string): Promise<any> {
    let configLive = null;
    if (this.isLive) {
      configLive = await this._liveConfig();
    }

    const clear = data.replace(/%2B/g, '+');
    const buff = new Buffer(clear, 'base64');
    const text = buff.toString('ascii');
    const p = new Parser();
    const result = await p.parseStringPromise(text);

    const showTitle = result['settings']['$']['title'] == 'true';
    const autoplay = result['settings']['$']['autoplay'];
    const onlyaudio = result['settings']['$']['onlyAudio'] !== undefined && result['settings']['$']['onlyAudio'] == 'true' ? true : false;
    const menu = result['settings']['$']['menu'];
    const controls_color = result['settings']['controls'][0]['$']['color'];
    const controls_alpha = result['settings']['controls'][0]['$']['alpha'];
    const controls_bkg = result['settings']['controls'][0]['$']['bkg'];
    const controls_bkd = result['settings']['controls'][0]['$']['bkd'];
    const controls_vidpic = result['settings']['controls'][0]['$']['vidPic'];

    const logo_visible = result['settings']['logo'][0]['$']['visible'];
    const logo_src = result['settings']['logo'][0]['$']['src'];
    const logo_url = result['settings']['logo'][0]['$']['url'];
    const logo_posn = parseInt(result['settings']['logo'][0]['$']['pos']);
    let isDvr = false;
    try{
      isDvr = result['settings']['$']['isDvr'] == 'true';
    }catch(e){
      // do nothing
    }
    // const isDvr = result['settings']['$']['isDvr'];
    // const isDvr = true;
    const showCCBtn = result['settings']['$']['showCCBtn'];
    let logo_pos = '';
    switch (logo_posn + 1) {
      case 1:
        logo_pos = 'top-left';
        break;
      case 2:
        logo_pos = 'top-left';
        break;
      case 3:
        logo_pos = 'top-right';
        break;
      case 4:
        logo_pos = 'top-left';
        break;
      case 5:
        logo_pos = 'top-left';
        break;
      case 6:
        logo_pos = 'top-left';
        break;
      case 7:
        logo_pos = 'bottom-left';
        break;
      case 8:
        logo_pos = 'bottom-left';
        break;
      case 9:
        logo_pos = 'bottom-right';
        break;
    }

    let analytics = {};
    try {
      const stdata = result['settings']['statistics'][0]['$'];
      if (stdata.kantarmedia !== '' || stdata.kantarmedia !== null) {
        analytics['kantarmedia'] = {
          kantarID: stdata.kantarmedia,
          kantarET: stdata.kantarmedia_ext_tv
        };
      }

      if (stdata.youboraid !== '' || stdata.youboraid !== null) {
        analytics['youbora'] = {
          id: stdata.youboraid,
          title: stdata.youboratitle
        };
      }

      if (stdata.comscoreid !== '' || stdata.comscoreid !== null) {
        analytics['comscore'] = {
          id: stdata.comscoreid
        };
      }

      if (stdata.adobe_analytics !== '' || stdata.adobe_analytics !== null) {
        analytics['adobe'] = {
          id: stdata.adobe_analytics
        };
      }
    } catch (e) {
      this.logger.debug(e);
    }

    let share = {
      facebook: false,
      twitter: false,
      download: false,
      source: false
    };
    try {
      const share_data = result['settings']['controls'][0]['share'][0]['network'];
      for (let item of share_data) {
        const name = item['$'].name;
        if (name == 'Facebook' && item['$'].visible == 'true') share.facebook = true;
        if (name == 'Twitter' && item['$'].visible == 'true') share.twitter = true;
        if (name == 'Share video' && item['$'].visible == 'true') share.source = true;
      }
    } catch (e) {
      this.logger.debug(e);
    }
    try {
      if (!this.isLive && !this.isDvr) if (result['settings']['controls'][0]['share'][0]['$']['download'] == 'true') share['download'] = true;
    } catch (e) {
      this.logger.debug(e);
    }

    let geoBlocking = [];
    let allowedDomains = [];

    if (this.isLive) {
      for (let item of Object.keys(configLive.geoBlocking)) {
        geoBlocking.push(item);
      }
      allowedDomains = configLive.domainsBlocked;
    }
    let brand = {src: null, url: null};
    // this.logger.debug("emil:", result.settings.brand[0]['$']);
    try {
      brand.src = result.settings.brand[0]['$'].src;
      brand.url = result.settings.brand[0]['$'].url;
    } catch (e) {
      // do nothing
    }
    let obj = {
      autoplay: autoplay,
      showTitle: showTitle,
      onlyaudio: onlyaudio,
      menu: menu,
      controls_color: controls_color,
      controls_alpha: controls_alpha,
      controls_bkg: controls_bkg,
      controls_bkd: controls_bkd,
      controls_vidpic: controls_vidpic,
      logo_visible: logo_visible,
      logo_src: logo_src,
      logo_url: logo_url,
      logo_pos: logo_pos,
      analytics: analytics,
      share: share,
      allowedDomains: allowedDomains,
      geoBlocking: geoBlocking,
      ivc_ads: this._ivcAds(result),
      vast: this._vast(result),
      branding: brand,
      isDvr: isDvr ,
      showCCBtn: showCCBtn
    };
    return obj;
  }

  async videoData(result: VideoMeta) {
    const videoEnc = md5(`definition-config-vod-${this.id}`);
    const data = await Http.execute(`${this.providerUrl}/definition/vod/videos/${this.user}/${videoEnc}.json`, null, 'GET');
    result.basics = {
      title: data.title,
      description: data.description,
      tags: null
    };
    result.isActive = data.videoIsActive == '1' || data.videoIsActive == 'true' || data.videoIsActive === undefined ? true : false;
    try {
      result.duration = data.duration !== undefined && data.duration ? data.duration : 1;
    } catch (e) {
      result.duration = 1;
    }

    const regex = /^(https?|http):\/\/[.a-z]+[.a-z0-9-]+/gm;

    // result.images.principal = data.prevPic.replace(regex, this.defaultJSON.storage[0]).replace('http:', 'https:');
    result.images.principal = `${this.defaultJSON.storage[0].replace('http:', 'https:')}/userdata/${this.user}/B${this.id}.jpg`;

    result.urls = {
      hls: data.srcMobileSSL.replace(regex, this.defaultJSON.vod[0]).replace('http:', 'https:'),
      mp4: data.streams.map(stream => `${this.defaultJSON.storage[0].replace('http:', 'https:')}/video/${this.user}/${stream}`)
    };

    result.isAudio = false;
    if (result.urls.hls.toLowerCase().indexOf('.m4a') > -1) result.isAudio = true;

    return result;
  }

  async vodCaptions() {
    let captions = null;
    try {
      captions = [];
      const infoData = await Http.execute(`${this.providerUrl}/definition/subtitles/${this.user}/${this.id}/${this.id}.json`, null, 'GET');
      for (let item of infoData) {
        captions.push({
          default: false,
          type: 'vtt',
          language: item.code,
          label: item.label,
          url: `${this.providerUrl}/definition/subtitles/${this.user}/${this.id}/${item.src}`
        });
      }
    } catch (e) {
      // Do nothing
    }
    return captions;
  }

  _ivcAds(result) {
    let ivc_ads = {
      pre_roll: {skipOffset: 0, src: null},
      mid_roll: {skipOffset: 0, src: null},
      pos_roll: {skipOffset: 0, src: null}
    };
    try {
      let pre_roll = {skipOffset: 0, src: null};
      try {
        if (result['settings']['videoAds'][0]['$']['pre_roll'] !== 'false' && result['settings']['videoAds'][0]['$']['pre_roll'] !== '') {
          pre_roll.src = result['settings']['videoAds'][0]['$']['pre_roll'];
          pre_roll.src = pre_roll.src.substring(pre_roll.src.indexOf('streams=') + 8, pre_roll.src.indexOf('.MP4'));
          pre_roll.src = `${this.defaultJSON.vod[0].replace('http://', 'https://')}/video/${this.user}/${pre_roll.src}.MP4`;
          pre_roll.skipOffset = parseInt(result['settings']['videoAds'][0]['$']['jmPnt_Pre']);
          pre_roll.skipOffset = pre_roll.skipOffset == 0 ? 0.1 : pre_roll.skipOffset;
        }
      } catch (e) {
        this.logger.debug(e);
      }

      let mid_roll = {skipOffset: 0, src: null};
      try {
        if (result['settings']['videoAds'][0]['$']['mid_roll'] !== 'false' && result['settings']['videoAds'][0]['$']['mid_roll'] !== '') {
          mid_roll.src = result['settings']['videoAds'][0]['$']['mid_roll'];
          mid_roll.src = mid_roll.src.substring(mid_roll.src.indexOf('streams=') + 8, mid_roll.src.indexOf('.MP4'));
          mid_roll.src = `${this.defaultJSON.vod[0].replace('http://', 'https://')}/video/${this.user}/${mid_roll.src}.MP4`;
          mid_roll.skipOffset = parseInt(result['settings']['videoAds'][0]['$']['jmPnt_Mid']);
          mid_roll.skipOffset = mid_roll.skipOffset == 0 ? 0.1 : mid_roll.skipOffset;
        }
      } catch (e) {
        this.logger.debug(e);
      }

      let pos_roll = {skipOffset: 0, src: null};
      try {
        if (result['settings']['videoAds'][0]['$']['pos_roll'] !== 'false' && result['settings']['videoAds'][0]['$']['pos_roll'] !== '') {
          pos_roll.src = result['settings']['videoAds'][0]['$']['pos_roll'];
          pos_roll.src = pos_roll.src.substring(pos_roll.src.indexOf('streams=') + 8, pos_roll.src.indexOf('.MP4'));
          pos_roll.src = `${this.defaultJSON.vod[0].replace('http://', 'https://')}/video/${this.user}/${pos_roll.src}.MP4`;
          pos_roll.skipOffset = parseInt(result['settings']['videoAds'][0]['$']['jmPnt_Pos']);
          pos_roll.skipOffset = pos_roll.skipOffset == 0 ? 0.1 : pos_roll.skipOffset;
        }
      } catch (e) {
        this.logger.debug(e);
      }
      ivc_ads.pre_roll = pre_roll;
      ivc_ads.mid_roll = mid_roll;
      ivc_ads.pos_roll = pos_roll;
    } catch (e) {
      this.logger.debug(e);
    }
    return ivc_ads;
  }

  _vast(result) {
    let ads = {url: null};
    try {
      try {
        if (
          result['settings']['adds'][0]['$']['vaspre'] != undefined &&
          result['settings']['adds'][0]['$']['vaspre'] !== 'false' &&
          result['settings']['adds'][0]['$']['vaspre'] !== ''
        ) {
          ads.url = decodeURIComponent(result['settings']['adds'][0]['$']['vaspre']);
        }
      } catch (e) {
        this.logger.debug(e);
      }
    } catch (e) {
      this.logger.debug(e);
    }
    return ads;
  }
}

export {JSONProvider};
